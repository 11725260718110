.maineditdiv{
    border: 2px solid black;
    width: 50%;
    margin: 20px;
    padding: 30px;
}
.editpageinput{
    border: 1px solid black;
    margin: 5px;
}
.editupdatebutton{
    border: 2px solid black;
    margin: 7px;
    padding: 8px;
}
.inputContainer {
    display: flex;
    align-items: center;
  }
  
  .plusButton {
    margin-left: 10px;
    cursor: pointer;
  }