.updatejobpage{
    margin: 50px;
   width: 50%;
    border:2px solid black;
    background-color: rgb(127, 210, 255);
}
.updateinput{
    border:1px solid black;
    margin: 5px;
}
.editIcon{
    font-size: 20px;
   margin-left: 680px;
}
.jobdetailsbutton{
    border: 2px solid black;
    background-color: white;
    margin: 15px;
    padding: 20px;
    width: 20%;
}