.spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.centered-rows .MuiDataGrid-row {
  display: flex;
  justify-content: center;
}
.light {
  background-color: #ffffff; /* Light mode background color */
  color: #000000; /* Light mode text color */
}

.dark {
  background-color: #162447; /* Dark mode background color */
  color: #d4d4d4; /* Dark mode text color */
}
.evenRow {
  background-color: #f2f2f2ad;
  /* color: black; */
}
.evenRow:hover {
  background-color: #b6b6b6;
  /* color: rgb(255, 255, 255); */
  cursor: pointer; /* Light blue shade for even rows */
}
.oddRow {
  /* background-color: #F4F0F0	; */
  /* color: black; */
}
.oddRow:hover {
  /* background-color: #ff0000; */
  /* color: rgb(255, 255, 255); */
  cursor: pointer;
}
.bold-header {
}
.cell-theme {
  border-right: 1px solid #ccc; /* Add vertical border */
}
.header-theme {
  font-weight: bold;
  border-right: 1px solid #ccc; /* Add vertical border */
}
.disabledRow {
  background-color: #252525; /* Light gray background */
  pointer-events: none; /* Prevent clicks */
}