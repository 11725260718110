@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

:root {
  --primary-blue: #2196f3;
  --light-blue: #e3f2fd;
  --dark-blue: #1565c0;
  --text-primary: #333333;
  --text-secondary: #666666;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #ffffff, var(--light-blue));
  padding: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo {
  width: 80px;
  height: auto;
  margin-bottom: 1.5rem;
}

.welcomeText {
  color: var(--primary-blue);
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.caption {
  color: var(--text-secondary);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.startButton {
  background-color: var(--primary-blue) !important;
  color: white !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  padding: 0.8rem 2rem !important;
  border-radius: 50px !important;
  transition: all 0.3s ease !important;
  text-transform: none !important;
  font-size: 1.1rem !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.startButton:hover {
  background-color: var(--dark-blue) !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
}

.timer {
  margin-top: 1.5rem;
  color: var(--text-secondary);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
}

/* Responsive styles for large devices (L: >1200px) */
@media (min-width: 1200px) {
  .container {
    min-width: 1200px;
    /* padding: 3rem; */
  }

  .content {
    padding: 4rem;
    max-width: 800px;
  }

  .welcomeText {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  .caption {
    font-size: 1.2rem;
    margin-bottom: 2.5rem;
  }

  .startButton {
    padding: 1rem 2rem !important;
    font-size: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  .container {
    min-width: 1400px;
    /* padding: 4rem; */
  }

  .content {
    padding: 5rem;
    max-width: 800px;
  }

  .welcomeText {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }

  .caption {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .startButton {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.3rem !important;
  }
}
@media (min-width: 1500px) {
  .container {
    min-width: 1500px;
    /* padding: 4rem; */
  }

  .content {
    padding: 5rem;
    max-width: 800px;
  }

  .welcomeText {
    font-size: 3.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  .caption {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .startButton {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.3rem !important;
  }
}
@media (min-width: 1600px) {
  .container {
    min-width: 1600px;
    /* padding: 4rem; */
  }

  .content {
    padding: 5rem;
    max-width: 800px;
  }

  .welcomeText {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }

  .caption {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .startButton {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.3rem !important;
  }
}
@media (min-width: 1700px) {
  .container {
    min-width: 1700px;
    /* padding: 4rem; */
  }

  .content {
    padding: 5rem;
    max-width: 800px;
  }

  .welcomeText {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }

  .caption {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .startButton {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.3rem !important;
  }
}
@media (min-width: 1900px) {
  .container {
    min-width: 1900px;
    /* padding: 4rem; */
  }

  .content {
    padding: 5rem;
    max-width: 800px;
  }

  .welcomeText {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }

  .caption {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .startButton {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.3rem !important;
  }
}
@media (min-width: 2000px) {
  .container {
    min-width: 2000px;
    /* padding: 4rem; */
  }

  .content {
    padding: 5rem;
    max-width: 1000px;
  }

  .welcomeText {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }

  .caption {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  .startButton {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.3rem !important;
  }
}
/* Responsive styles for smaller devices (S: max-width 600px) */
@media (max-width: 600px) {
  .content {
    padding: 2rem;
  }

  .welcomeText {
    font-size: 2rem;
  }

  .caption {
    font-size: 0.9rem;
  }
}
