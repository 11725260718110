.jobDetailsbutton{
  border: 1px solid black;
  margin: 40px;
  padding: 10px;
}
.tableJobDetails{
  border: 2px solid black;
  margin: 40px;
  width: 800px;
  height: 200px;
  border-collapse: collapse;
}
.JobDetailsth {
  border-bottom: 1px solid black;
  border: 2px solid black;
}

.JobDetailstr {
  text-align: center;
  border: 2px solid black;
  color: black !important;
}